module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"GEL IELTS","start_url":"/","display":"minimal-ui","icon":"src/images/favicon/favicon.png","background_color":"#ffffff","theme_color":"#ffffff","icons":[{"src":"src/images/favicon/android-chrome-192x192.png","sizes":"192x192","type":"image/png","purpose":"any"},{"src":"src/images/favicon/android-chrome-512x512.png","sizes":"512x512","type":"image/png","purpose":"any maskable"},{"src":"src/images/favicon/apple-touch-icon.png","sizes":"180x180","type":"image/png","purpose":"any"},{"src":"src/images/favicon/favicon-32x32.png","sizes":"32x32","type":"image/png","purpose":"any"},{"src":"src/images/favicon/favicon-16x16.png","sizes":"16x16","type":"image/png","purpose":"any"},{"src":"src/images/favicon/mstile-150x150.png","sizes":"150x150","type":"image/png","purpose":"any"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fc95d93a77fd14d806deb1b63fc8934a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"GTM-KW2N356","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
